<template>
  <div>
    <div class="mb-4">
      {{ 'upload_clipboard.confirm.content'|t({ quantity }) }}
    </div>

    <uploadFilePreview
      v-for="(file, index) in files"
      :key="index"
      :file="file"
      class="mb-6"
    ></uploadFilePreview>
  </div>
</template>

<script>
export default {
  props: {
    popupConfig: Object,
  },
  data: () => ({

  }),
  created() {},
  computed: {
    quantity() {
      if(!Array.isArray(this.files)) return 0
      return this.files.length
    },
    files() {
      return this.popupConfig.files
    },
  },
  components: {
    uploadFilePreview: () => import('./uploadFilePreview.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
